
const digest = 'c9c542173a00762ec70e3130f36c47a008f72102761ea9b81f370b8822aa6291';
const css = `._badge_s0ru2_1 {
  color: var(--color-white);
  background: var(--color--neutral-10);
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.375rem;
  height: 1rem;
  width: 1rem;
  border-radius: 2px;
  font-weight: 600;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"badge":"_badge_s0ru2_1"};
export { css, digest };
  