
const digest = '3761a89655249baefc0be63a494cc0edb506462f89534472eb51ddda4a0735fc';
const css = `._weekPicker_1xmh3_1 {
  z-index: 5;

  align-items: center;
  background-color: white;
  border: 1px solid var(--color--neutral-10);
  border-radius: var(--border-radius);
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 12rem;
  margin: 0 0.25rem;
}

._weekPicker_1xmh3_1 .DayPicker_transitionContainer {
  box-shadow: 0 0.25rem 1rem rgba(1, 22, 39, 0.125);
}

._weekPicker_1xmh3_1 .SingleDatePickerInput {
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 7rem;
}

._weekPicker_1xmh3_1 .SingleDatePicker_picker {
  /* fix issue with overlapping border */
  transform: translateY(-1px);
}

.week-picker-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

._weekPicker_1xmh3_1 .DayPicker_transitionContainer {
  box-shadow: 0 0.25rem 1rem rgba(1, 22, 39, 0.125);
}

._weekPicker_1xmh3_1 .DateInput_input {
  border: none;
  color: #011627;
  font-size: 1rem;
  font-weight: 400;
  /* offset border */
  height: calc(2.5rem - 2px);
  padding: 0.5rem 1rem;
}

._weekPicker_1xmh3_1 .DateInput_input__focused {
  border-bottom: 2px solid var(--color-brand);
  transform: translateY(1px);
}

._weekPicker_1xmh3_1 .CalendarDay__default:hover {
  background: white;
}

._navButton_1xmh3_63 {
  background: transparent;
  border: none;
  /* border-right: 1px solid var(--color--neutral-20); */
  display: flex;
  height: 2.5rem;
  padding: 0;
  width: 2.5rem;
}

._prev_1xmh3_73 {
  border-right: 1px solid var(--color--neutral-10);
}

._next_1xmh3_77 {
  border-left: 1px solid var(--color--neutral-10);
}

._navButton_1xmh3_63:disabled {
  opacity: 0.4;
}

._CalendarDay_1xmh3_59 {
  /* background: var(--color-white); */
  color: var(--color-neutral-40);
  height: 38px;
  width: 39px;
}

._CalendarDay_1xmh3_59._isHighlighted_1xmh3_92 {
  background: var(--color--brand-30);
  border: 1px solid var(--color--brand-30);
  color: var(--color-neutral-40);
}

._CalendarDay_1xmh3_59._isSelected_1xmh3_98 {
  background: var(--color-brand);
  border: 1px solid var(--color-brand);
  color: var(--color-white);
}

._CalendarDay_1xmh3_59._isHovered_1xmh3_104,
._CalendarDay_1xmh3_59._isHovered_1xmh3_104:hover {
  background: var(--color--brand-10);
  border: 1px solid var(--color--brand-10);
  color: var(--color-white);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"weekPicker":"_weekPicker_1xmh3_1","navButton":"_navButton_1xmh3_63","prev":"_prev_1xmh3_73","next":"_next_1xmh3_77","CalendarDay":"_CalendarDay_1xmh3_59","calendarDay":"_CalendarDay_1xmh3_59","isHighlighted":"_isHighlighted_1xmh3_92","isSelected":"_isSelected_1xmh3_98","isHovered":"_isHovered_1xmh3_104"};
export { css, digest };
  