
const digest = 'd5831292a19f5d75414654690587de80b4c7e26bf9ff24ff3b7e85b2ee5a0274';
const css = `._negative_1u5vo_1 {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_1u5vo_1"};
export { css, digest };
  